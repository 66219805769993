<template>
  <div class="container">
    <div class="container_top">
      <div class="container_top_info">
        <div class="container_top_info_left">
          <div class="container_top_info_left_header">
            <img
              :src="(user?.avatar!=null&&user?.avatar!=='')?user?.avatar:avatar"
              alt=""
            >
          </div>
          <div class="container_top_info_left_info">
            <div class="container_top_info_left_info_title">{{
                user?.truename ? (user?.truename) : (user?.username)
              }}
            </div>
            <div
              class="container_top_info_left_info_level"
              v-if="user?.type!='1'"
            >
              <div
                class="container_top_info_left_info_level_star"
                v-if="user?.type!='1'"
              >
                {{ user.vipRange ? user.vipRange : "1" }}星志愿者
              </div>
              <div
                class="container_top_info_left_info_level_images"
                v-if="user?.type!='1'"
              >
                <img
                  v-for="(item, i) in starLength"
                  :key="i"
                  src="../../assets/images/activity/star.png"
                  alt=""
                >
              </div>
            </div>
            <div
              class="container_top_info_left_info_timelen"
              v-if="user?.type!='1'"
            >志愿时长
              {{ user.timeLen ? user.timeLen : "0" }}
            </div>
          </div>
        </div>
        <div class="container_top_info_prof">
          <div
            class="container_top_info_prof_right"
            @click="showProfApply"
          >
            <span><img
                src="../../assets/images/doc.png"
                alt=""
              ></span><span>申请证书</span>
          </div>
          <div
            class="container_top_info_prof_me"
            @click="showMyprofDialog"
          >
            <span><img
                src="../../assets/images/doc_white.png"
                alt=""
              ></span><span>我的证书</span>
          </div>
        </div>
      </div>
      <div
        class="container_top_progress"
        v-if="user?.type!='1'"
      >
        <el-progress
          :percentage="per"
          status="warning"
          stroke-width="3"
          :format="format"
        />
      </div>
    </div>
    <div class="container_content">
      <div class="container_content_left">
        <div class="container_content_left_pay">
          <div class="container_content_left_pay_left">
            <div class="container_content_left_pay_left_title"><span><img
                  src="../../assets/images/purl.png"
                  alt=""
                ></span> <span>余额</span></div>
            <div class="container_content_left_pay_left_money">￥{{ user.money / 100 }}</div>
          </div>
          <div class="container_content_left_pay_right">
            <el-button
              plain
              size="large"
              @click="showPayDialog('wx')"
            >微信充值
            </el-button>
            <el-button
              plain
              size="large"
              @click="showPayDialog('ali')"
            >支付宝充值
            </el-button>
          </div>
        </div>
        <!-- <div
          class="container_content_left_pay"
          @click="clickShowMsg"
        >
          <div class="container_content_left_pay_left">
            <div class="container_content_left_pay_left_title"><span><img
                  src="../../assets/images/message.png"
                  alt=""
                ></span> <span>消息通知</span></div>
          </div>
          <div class="container_content_left_pay_right">
            <img
              src="../../assets/images/right-arrow.png"
              alt=""
            >
          </div>
        </div> -->

        <div class="container_content_left_block">
          <div
            class="container_content_left_block_row"
            @click="clickShowAct"
          >
            <div class="container_content_left_block_row_left">
              <div class="container_content_left_block_row_left_title"><span><img
                    src="../../assets/images/myact.png"
                    alt=""
                  ></span> <span>我的活动</span></div>
            </div>
            <div class="container_content_left_block_row_right">
              <img
                src="../../assets/images/right-arrow.png"
                alt=""
              >
            </div>
          </div>
          <div class="container_content_left_block_div">
            <el-divider />
          </div>

          <div
            class="container_content_left_block_row"
            @click="clickShowMsg"
          >
            <div class="container_content_left_block_row_left">
              <div class="container_content_left_block_row_left_title"><span><img
                    src="../../assets/images/message.png"
                    alt=""
                  ></span> <span>消息通知</span></div>
            </div>
            <div class="container_content_left_block_row_right">
              <img
                src="../../assets/images/right-arrow.png"
                alt=""
              >
            </div>
          </div>
        </div>

        <div class="container_content_left_block">
          <div
            class="container_content_left_block_row"
            @click="clickShowPerson"
          >
            <div class="container_content_left_block_row_left">
              <div class="container_content_left_block_row_left_title"><span><img
                    src="../../assets/images/material.png"
                    alt=""
                  ></span> <span>个人资料</span></div>
            </div>
            <div class="container_content_left_block_row_right">
              <img
                src="../../assets/images/right-arrow.png"
                alt=""
              >
            </div>
          </div>
          <div class="container_content_left_block_div">
            <el-divider />
          </div>

          <div
            class="container_content_left_block_row"
            @click="clickShowAddress"
          >
            <div class="container_content_left_block_row_left">
              <div class="container_content_left_block_row_left_title"><span><img
                    src="../../assets/images/shdz.png"
                    alt=""
                  ></span> <span>收货地址</span></div>
            </div>
            <div class="container_content_left_block_row_right">
              <img
                src="../../assets/images/right-arrow.png"
                alt=""
              >
            </div>
          </div>
        </div>
        <div
          class="container_content_left_pay"
          @click="showApplyDialog"
          v-if="user?.type=='1'||user?.type=='2'||user?.type=='3'||user?.type=='5'"
        >
          <div class="container_content_left_pay_left">
            <div class="container_content_left_pay_left_title"><span><img
                  src="../../assets/images/shenq.png"
                  alt=""
                ></span> <span>入驻申请</span></div>
          </div>
          <div class="container_content_left_pay_right">
            <img
              src="../../assets/images/right-arrow.png"
              alt=""
            >
          </div>
        </div>

        <div class="container_content_left_block">
          <div
            class="container_content_left_block_row"
            @click="logout"
          >
            <div class="container_content_left_block_row_left">
              <div class="container_content_left_block_row_left_title"><span><img
                    src="../../assets/images/material.png"
                    alt=""
                  ></span> <span>退出登录</span></div>
            </div>
            <div class="container_content_left_block_row_right">
              <img
                src="../../assets/images/right-arrow.png"
                alt=""
              >
            </div>
          </div>
        </div>

      </div>
      <div class="container_content_right">
        <!---默认显示--->
        <div
          class="container_content_right_default"
          v-if="showAct"
        >
          <div>
            <el-tabs
              v-model="activeName"
              class="demo-tabs"
              @tab-click="handleClick"
            >
              <el-tab-pane
                label="我的发布"
                name="first"
              ></el-tab-pane>
              <el-tab-pane
                label="我的参与"
                name="second"
              ></el-tab-pane>
              <el-tab-pane
                label="积分记录"
                name="third"
              ></el-tab-pane>
              <el-tab-pane
                label="兑换记录"
                name="fourth"
              ></el-tab-pane>
            </el-tabs>
          </div>

          <div
            class="container_content_act"
            v-if="tabIndex==0"
          >
            <el-tabs
              v-model="activeName"
              type="card"
              class="demo-tabs"
              @tab-click="handleActClick"
            >
              <el-tab-pane
                :label="tab"
                :name="tab"
                v-for="(tab,index) in tablist"
                :key="index"
              >
                <div class="container_content_right_counts">共参与{{ totalAct }}个活动</div>
                <div class="container_content_right_acts">
                  <div
                    class="container_content_right_acts_item"
                    v-for="(actItem, actIndex) in actList"
                    :key="actIndex"
                    @click="toActDetail(actItem.id)"
                  >
                    <div class="container_content_right_acts_item_pic">
                      <img
                        :src="actItem?.picUrl"
                        alt=""
                      >
                    </div>
                    <div class="container_content_right_acts_item_info">
                      <div class="container_content_right_acts_item_info_title">
                        {{ actItem?.name }}
                      </div>
                    </div>
                    <div class="container_content_right_acts_item_tags">
                      <div class="container_content_right_acts_item_tags_leftpart">
                        <el-tag
                          class="ml-2"
                          type="success"
                          style="margin-right:5px"
                        > {{
                            actItem.checkStatus === "0" ? "待审核" : (actItem.checkStatus === "2" ? "已驳回" : (actItem.status === '0' ? '已结束' : (actItem.status === '1' ? '进行中' : (actItem.status === '2' ? '报名中' : '未开始'))))
                          }}
                        </el-tag>
                        <el-tag
                          class="ml-2"
                          type="danger"
                        > {{ actItem?.activityType?.name }}
                        </el-tag>
                      </div>
                      <div class="container_content_right_acts_item_tags_rightpart">
                        发布于{{ actItem.createTime.substring(0, 10).replaceAll('-', '.') }}
                      </div>
                    </div>

                  </div>
                  <div class="container_block_page">
                    <pagination
                      v-show="totalAct > 0"
                      :total="totalAct"
                      v-model:page="queryAct.pageNum"
                      v-model:limit="queryAct.pageSize"
                      @pagination="activityList"
                    />

                  </div>
                </div>
              </el-tab-pane>

            </el-tabs>
          </div>

          <div
            class="container_content_act"
            v-if="tabIndex==1"
          >
            <div class="container_content_right_counts">共参与{{ totalJoin }}个活动</div>
            <div class="container_content_right_acts">
              <div
                class="container_content_right_acts_item"
                v-for="(itemJoin,index) in actJoinList"
                :key="index"
                @click="toActDetail(itemJoin.id)"
              >
                <div class="container_content_right_acts_item_pic">
                  <img
                    :src="itemJoin?.picUrl"
                    alt=""
                  >
                </div>
                <div class="container_content_right_acts_item_info">
                  <div class="container_content_right_acts_item_info_title">
                    {{ itemJoin?.name }}
                  </div>
                </div>
                <div class="container_content_right_acts_item_tags">
                  <div class="container_content_right_acts_item_tags_leftpart">
                    <el-tag
                      class="ml-2"
                      type="success"
                      style="margin-right:5px"
                    > {{
                        itemJoin.checkStatus === "0" ? "待审核" : (itemJoin.checkStatus === "2" ? "已驳回" : (itemJoin.status === '0' ? '已结束' : (itemJoin.status === '1' ? '进行中' : '报名中')))
                      }}
                    </el-tag>
                    <el-tag
                      class="ml-2"
                      type="danger"
                    >{{ itemJoin?.activityType?.name }}
                    </el-tag>
                  </div>
                  <div class="container_content_right_acts_item_tags_rightpart">
                    发布于{{ itemJoin.createTime.substring(0, 10).replaceAll('-', '.') }}
                  </div>
                </div>

              </div>
              <div class="container_block_page">
                <pagination
                  v-show="totalJoin > 0"
                  :total="totalJoin"
                  v-model:page="queryJoin.pageNum"
                  v-model:limit="queryJoin.pageSize"
                  @pagination="activityJoinList"
                />

              </div>
            </div>
          </div>
          <div
            class="container_content_score"
            v-if="tabIndex==2"
          >
            <div class="container_content_right_counts">可用积分</div>
            <div class="container_content_right_title"> {{ user.score }}</div>
            <div class="container_content_right_table">
              <el-table
                :data="scoreList"
                border
                style="width: 100%"
              >
                <el-table-column
                  prop="changeType"
                  label="类型"
                  width="180"
                  :formatter="typeFormatter"
                />

                <el-table-column
                  prop="changeScore"
                  label="数值"
                />
                <el-table-column
                  prop="createTime"
                  label="时间"
                />
              </el-table>
              <div class="container_block_page">
                <pagination
                  v-show="totalScore > 0"
                  :total="totalScore"
                  v-model:page="queryScore.pageNum"
                  v-model:limit="queryScore.pageSize"
                  @pagination="getScoreList"
                />

              </div>
            </div>
          </div>

          <div
            class="container_content_score"
            v-if="tabIndex==3"
          >
            <div class="container_content_right_counts">已兑换积分</div>
            <div class="container_content_right_title"> {{ score }}</div>
            <div class="container_content_right_list">

              <div
                class="container_content_right_list_item"
                v-for="(itemEx,index) in exchangeList"
                :key="index"
              >
                <div class="container_content_right_list_item_pic"><img
                    :src="itemEx.images.length > 0 ? itemEx.images[0]?.url: pic"
                    alt=""
                  >
                </div>
                <div class="container_content_right_list_item_info">
                  <div class="container_content_right_list_item_info_title"> {{ itemEx.name }}</div>

                  <div
                    class="container_content_right_list_item_info_price"
                    v-if="itemEx.payType==='1'"
                  ><span>价格： {{ itemEx.isRebate === '0' ? itemEx.discountPrice : itemEx.originalPrice }}元</span><span>时间：{{ itemEx.orderCreateTime }}兑换</span>
                  </div>
                  <div
                    class="container_content_right_list_item_info_price"
                    v-else-if="itemEx.payType==='2'"
                  ><span>价格： {{ itemEx.score }}积分</span><span>时间：{{ itemEx.orderCreateTime }}兑换</span>
                  </div>
                  <div
                    class="container_content_right_list_item_info_price"
                    v-else-if="itemEx.payType==='3'"
                  >
                    <span>价格： {{ itemEx.isRebate === '0' ? itemEx.discountPrice : itemEx.originalPrice }}元+{{ itemEx.score }}积分</span><span>时间：{{ itemEx.orderCreateTime }}兑换</span>
                  </div>

                </div>
              </div>

            </div>
            <div class="container_block_page">
              <pagination
                v-show="exchangeTotal > 0"
                :total="exchangeTotal"
                v-model:page="queryChange.pageNum"
                v-model:limit="queryChange.pageSize"
                @pagination="getExchangeList"
              />

            </div>
          </div>

        </div>
        <!---消息列表--->
        <div
          class="container_content_right_msg"
          v-if="showMsg"
        >
          <div class="container_content_right_msg_title">消息列表</div>
          <el-divider></el-divider>
          <div
            class="container_content_right_msg_list"
            v-if="!messageIsEmpty"
          >
            <div
              class="container_content_right_msg_list_wrap"
              v-for="(item, i) in messageList"
              :key="i"
            >
              <div
                class="container_content_right_msg_list_item"
                @click="toMessageDetail(item.id)"
              >
                <div class="container_content_right_msg_list_item_head"><span class="container_content_right_msg_list_item_bell"><img
                      src="../../assets/images/bells.png"
                      alt=""
                    ></span><span>{{ item?.title }}</span></div>
                <div class="container_content_right_msg_list_item_footer"><span class="container_content_right_msg_list_item_date">{{ item?.createTime }}</span><span><img
                      src="../../assets/images/right-arrow.png"
                      alt=""
                    ></span></div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <div
            class="container_content_right_msg_empty"
            v-if="messageIsEmpty"
          >
            <el-empty description="暂无数据" />
          </div>
          <div
            class="container_content_right_msg_page"
            v-if="!messageIsEmpty"
          >
            <el-pagination
              background
              :page-size="messageQuery.pageSize"
              @current-change="messageCurrentChange"
              @prev-click="messageUpPage"
              @next-click="messageDownPage"
              layout="prev, pager, next"
              :total="messageTotals"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 消息详细     -->
        <div
          class="container_content_right_msg"
          v-if="showMsgDetail"
        >
          <div
            class="container_content_right_msg_title"
            @click="toMessageList"
          ><span style="color: #ff9959">&lt;&nbsp;&nbsp;&nbsp;返回消息列表</span>
          </div>
          <el-divider></el-divider>
          <div class="container_content_right_msg_title"><span style="color: #333333;font-weight: bolder">{{ messageTitle }}</span></div>
          <el-divider></el-divider>
          <div
            class="container_content_right_msg_content"
            v-html="messageContent"
          >
          </div>
        </div>
        <!---个人资料开始--->
        <div
          class="container_content_right_material"
          v-if="showPerson"
        >
          <el-form
            :model="form"
            label-position="top"
            label-width="100px"
            :inline="true"
          >
            <div class="container_content_right_material_block container_content_right_material_block--width">
              <div class="container_content_right_material_block_title">会员ID：{{ user?.cardnum }}</div>
              <el-divider></el-divider>
              <div class="container_content_right_material_block_content ">

                <el-form-item v-if="userType!=='1'"
                  label="所属站点"
                  prop="stationId"
                >
                  <el-tag style="height: 30px"
                    v-html="stationName"
                    disabled
                  />

                </el-form-item>
                <el-form-item v-if="userType!=='1'"
                  label="所属服务队"
                  prop="stationId"
                >
                  <el-tag style="height: 30px"
                    v-html="teamName"
                    disabled
                  />

                </el-form-item>
                <el-form-item
                  label="用户类型"
                  prop="stationId"
                >
                  <el-input
                    v-model="typeName"
                    class="w-50 m-2"
                    size="large"
                    disabled
                  />

                </el-form-item>

              </div>
            </div>

            <div class="container_content_right_material_block container_content_right_material_block--width">
              <div class="container_content_right_material_block_titles">基本信息</div>
              <el-divider></el-divider>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="会员名"
                  prop="username"
                >
                  <el-input
                    v-model="form.username"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="真实姓名"
                  prop="truename"
                >
                  <el-input
                    v-model="form.truename"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="性别"
                  prop="sex"
                >
                  <el-select
                    v-model="form.sex"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      label="男"
                      value="0"
                    />
                    <el-option
                      label="女"
                      value="1"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="身份证号"
                  prop="idCard"
                >
                  <el-input
                    v-model="form.idCard"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="民族"
                  prop="nation"
                >
                  <el-input
                    v-model="form.nation"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="政治面貌"
                  prop="political"
                >
                  <el-select
                    v-model="form.political"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      label="党员"
                      value="1"
                    />
                    <el-option
                      label="预备党员"
                      value="2"
                    />
                    <el-option
                      label="共青团员"
                      value="3"
                    />
                    <el-option
                      label="无党派人士"
                      value="4"
                    />
                    <el-option
                      label="群众"
                      value="5"
                    />
                  </el-select>

                </el-form-item>

              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="籍贯"
                  prop="birthplace"
                >
                  <el-input
                    v-model="form.birthplace"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="宗教信仰"
                  prop="belief"
                >
                  <el-input
                    v-model="form.belief"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="出生年月"
                  prop="birthday"
                >
                  <el-date-picker
                    v-model="form.birthday"
                    type="datetime"
                    placeholder="请选择"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                  />
                </el-form-item>

              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="真实头像"
                  prop="trueHeadImg"
                >
                  <el-upload
                    accept=".jpg,.png,.JPG,.PNG,.jpeg"
                    class="avatar-uploader"
                    :action="uploadFileUrl"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :headers="headers"
                    :on-error="handleUploadError"
                    :on-success="handleSuccess"
                    :on-exceed="handleExceed"
                  >
                    <img
                      v-if="trueHeadImg"
                      :src="trueHeadImg"
                      class="avatar"
                    />
                    <el-icon
                      v-else
                      class="avatar-uploader-icon"
                    >
                      <img
                        src="../../assets/images/activity/upload.png"
                        alt=""
                      >
                      请上传
                    </el-icon>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
            <div class="container_content_right_material_block container_content_right_material_block--width">
              <div class="container_content_right_material_block_titles">教育信息</div>
              <el-divider></el-divider>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="毕业学校"
                  prop="school"
                >
                  <el-input
                    v-model="form.school"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="专业"
                  prop="professional"
                >
                  <el-input
                    v-model="form.professional"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="学历"
                  prop="education"
                >
                  <el-select
                    v-model="form.education"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      label="初中"
                      value="1"
                    />
                    <el-option
                      label="中专/高中"
                      value="2"
                    />
                    <el-option
                      label="专科"
                      value="3"
                    />
                    <el-option
                      label="本科"
                      value="4"
                    />
                    <el-option
                      label="硕士及以上"
                      value="5"
                    />
                  </el-select>

                </el-form-item>

              </div>

            </div>
            <div class="container_content_right_material_block container_content_right_material_block--width">
              <div class="container_content_right_material_block_titles">其他信息</div>
              <el-divider></el-divider>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="工作单位"
                  prop="employer"
                >
                  <el-input
                    v-model="form.employer"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />

                </el-form-item>
                <el-form-item
                  label="技能、特长"
                  prop="skill"
                >
                  <el-input
                    v-model="form.skill"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item
                  label="职务"
                  prop="job"
                >
                  <el-select
                    v-model="form.job"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      label="公务员"
                      value="1"
                    />
                    <el-option
                      label="国企员工"
                      value="2"
                    />
                    <el-option
                      label="事业编"
                      value="3"
                    />
                    <el-option
                      label="民企员工"
                      value="4"
                    />
                    <el-option
                      label="企业主"
                      value="5"
                    />
                    <el-option
                      label="自由职业"
                      value="6"
                    />
                    <el-option
                      label="其他"
                      value="7"
                    />
                  </el-select>
                </el-form-item>

              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="兴趣爱好"
                  prop="hobby"
                >
                  <el-input
                    v-model="form.hobby"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item
                  label="户口所在地"
                  prop="hukou"
                >
                  <el-input
                    v-model="form.hukou"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item
                  label="暂住证号码"
                  prop="trp"
                >
                  <el-input
                    v-model="form.trp"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="邮编"
                  prop="postcode"
                >
                  <el-input
                    v-model="form.postcode"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item
                  label="通信地址"
                  prop="correspond"
                >
                  <el-input
                    v-model="form.correspond"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item
                  label="email"
                  prop="email"
                >
                  <el-input
                    v-model="form.email"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="微信号"
                  prop="wechat"
                >
                  <el-input
                    v-model="form.wechat"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item
                  label="手机号"
                  prop="phone"
                >
                  <el-input
                    v-model="form.phone"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item
                  label="单位或学校电话"
                  prop="unitPhone"
                >
                  <el-input
                    v-model="form.unitPhone"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="其他联系方式"
                  prop="otherPhone"
                >
                  <el-input
                    v-model="form.otherPhone"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                  />
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="服务简历"
                  prop="resume"
                >
                  <el-input
                    v-model="form.resume"
                    resize='none'
                    type="textarea"
                    :rows="5"
                    placeholder="请输入"
                    input-style="width:600px"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="container_content_right_material_block container_content_right_material_block--width">
              <div class="container_content_right_material_block_titles">服务时段</div>
              <el-divider></el-divider>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="周一"
                  prop="periodList.weekOne"
                >
                  <el-checkbox-group v-model="form.periodList.weekOne">
                    <el-checkbox
                      label="08:00-12:00"
                      name="weekOne"
                    />
                    <el-checkbox
                      label="12:00-14:00"
                      name="weekOne"
                    />
                    <el-checkbox
                      label="14:00-18:00"
                      name="weekOne"
                    />
                    <el-checkbox
                      label="18:00-21:00"
                      name="weekOne"
                    />
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  label="周二"
                  prop="periodList.weekTwo"
                >
                  <el-checkbox-group v-model="form.periodList.weekTwo">
                    <el-checkbox
                      label="08:00-12:00"
                      name="weekTwo"
                    />
                    <el-checkbox
                      label="12:00-14:00"
                      name="weekTwo"
                    />
                    <el-checkbox
                      label="14:00-18:00"
                      name="weekTwo"
                    />
                    <el-checkbox
                      label="18:00-21:00"
                      name="weekTwo"
                    />
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="周三"
                  prop="periodList.weekThree"
                >
                  <el-checkbox-group v-model="form.periodList.weekThree">
                    <el-checkbox
                      label="08:00-12:00"
                      name="weekThree"
                    />
                    <el-checkbox
                      label="12:00-14:00"
                      name="weekThree"
                    />
                    <el-checkbox
                      label="14:00-18:00"
                      name="weekThree"
                    />
                    <el-checkbox
                      label="18:00-21:00"
                      name="weekThree"
                    />
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  label="周四"
                  prop="periodList.weekFour"
                >
                  <el-checkbox-group v-model="form.periodList.weekFour">
                    <el-checkbox
                      label="08:00-12:00"
                      name="weekFour"
                    />
                    <el-checkbox
                      label="12:00-14:00"
                      name="weekFour"
                    />
                    <el-checkbox
                      label="14:00-18:00"
                      name="weekFour"
                    />
                    <el-checkbox
                      label="18:00-21:00"
                      name="weekFour"
                    />
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="周五"
                  prop="periodList.weekFive"
                >
                  <el-checkbox-group v-model="form.periodList.weekFive">
                    <el-checkbox
                      label="08:00-12:00"
                      name="weekFive"
                    />
                    <el-checkbox
                      label="12:00-14:00"
                      name="weekFive"
                    />
                    <el-checkbox
                      label="14:00-18:00"
                      name="weekFive"
                    />
                    <el-checkbox
                      label="18:00-21:00"
                      name="weekFive"
                    />
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item
                  label="周六"
                  prop="periodList.weekSix"
                >
                  <el-checkbox-group v-model="form.periodList.weekSix">
                    <el-checkbox
                      label="08:00-12:00"
                      name="weekSix"
                    />
                    <el-checkbox
                      label="12:00-14:00"
                      name="weekSix"
                    />
                    <el-checkbox
                      label="14:00-18:00"
                      name="weekSix"
                    />
                    <el-checkbox
                      label="18:00-21:00"
                      name="weekSix"
                    />
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  label="周日"
                  prop="periodList.weekSun"
                >
                  <el-checkbox-group v-model="form.periodList.weekSun">
                    <el-checkbox
                      label="08:00-12:00"
                      name="weekSun"
                    />
                    <el-checkbox
                      label="12:00-14:00"
                      name="weekSun"
                    />
                    <el-checkbox
                      label="14:00-18:00"
                      name="weekSun"
                    />
                    <el-checkbox
                      label="18:00-21:00"
                      name="weekSun"
                    />
                  </el-checkbox-group>
                </el-form-item>
              </div>

            </div>
            <div class="container_content_right_material_block container_content_right_material_block--width">
              <div class="container_content_right_material_block_titles">常规可从事志愿服务项目</div>
              <el-divider></el-divider>
              <div class="container_content_right_material_block_content">
                <el-form-item
                  style="width: 870px;text-align: left"
                  prop="projectList"
                >
                  <el-checkbox-group v-model="form.projectList">
                    <el-checkbox
                      label="扶孤助残"
                      name="projectList"
                    />
                    <el-checkbox
                      label="心理咨询"
                      name="projectList"
                    />
                    <el-checkbox
                      label="科技成果"
                      name="projectList"
                    />
                    <el-checkbox
                      label="农业技术"
                      name="projectList"
                    />
                    <el-checkbox
                      label="大型活动"
                      name="projectList"
                    />
                    <el-checkbox
                      label="教育服务"
                      name="projectList"
                    />
                    <el-checkbox
                      label="物资捐助"
                      name="projectList"
                    />
                    <el-checkbox
                      label="医疗保健"
                      name="projectList"
                    />
                    <el-checkbox
                      label="活动宣传"
                      name="projectList"
                    />
                    <el-checkbox
                      label="文艺汇演"
                      name="projectList"
                    />
                    <el-checkbox
                      label="应急服务"
                      name="projectList"
                    />
                    <el-checkbox
                      label="专业技能"
                      name="projectList"
                    />
                    <el-checkbox
                      label="法律服务"
                      name="projectList"
                    />
                    <el-checkbox
                      label="环境整治"
                      name="projectList"
                    />
                    <el-checkbox
                      label="其他"
                      name="projectList"
                    />
                  </el-checkbox-group>
                </el-form-item>
              </div>

            </div>
            <div class="container_content_right_material_btn container_content_right_material_block--width">
              <el-button
                type="warning"
                @click="submitUserInfo"
              >保存
              </el-button>
            </div>
          </el-form>
        </div>
        <!---个人资料结束--->
        <!---收货地址开始--->
        <div
          class="container_content_right_material"
          v-if="showAddress"
        >

          <div
            class="container_content_right_material_block"
            v-for="(aditem,index) in addressList"
            :key="index"
          >
            <el-form
              :model="formAddress"
              label-position="top"
              label-width="100px"
              :inline="true"
            >
              <div class="container_content_right_material_block_titleaddress">
                <div><span><img
                      src="../../assets/images/roadtag.png"
                      alt=""
                    ></span> 收货地址
                </div>

                <div><span
                    class="container_content_right_material_block_titleaddress_edit"
                    @click="editAddress(aditem)"
                  >修改</span> <span
                    class="container_content_right_material_block_titleaddress_del"
                    @click="delAddress(aditem.id)"
                  > 删除</span>

                </div>

              </div>
              <el-divider></el-divider>
              <div class="container_content_right_material_block_content">

                <el-form-item
                  label="姓名"
                  prop="name"
                >
                  <el-input
                    v-model="aditem.name"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                    disabled
                  />

                </el-form-item>
                <el-form-item
                  label="手机号"
                  prop="phonenumber"
                >
                  <el-input
                    v-model="aditem.phonenumber"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                    disabled
                  />

                </el-form-item>
                <el-form-item
                  label="详细地址"
                  prop="address"
                >
                  <el-input
                    v-model="aditem.address"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                    disabled
                  />

                </el-form-item>

              </div>
            </el-form>
          </div>
          <div class="container_content_right_material_btn">
            <el-button
              type="warning"
              @click="showAddressDialog"
            >新增收货地址
            </el-button>
          </div>

        </div>

        <!---收货地址结束--->

      </div>
    </div>
    <!-- 余额充值 dialog -->
    <el-dialog
      v-model="payDialogVisible"
      title="余额充值"
      :before-close="closePayDialog"
    >
      <div class="paydialog">
        <div class="paydialog_left">
          <div class="paydialog_left_recharge">充值金额<span class="paydialog_left_recharge_money">&yen;{{ payMoney }}</span></div>
          <div class="paydialog_left_qrcode">
            <qrcode-vue
              :value="qrCodeUrl"
              :size="300"
              level="H"
            />
            <!-- <img
              src="../../assets/images/qrcode.png"
              alt=""
            > -->
          </div>
          <div class="paydialog_left_btn"><span><img
                src="../../assets/images/scan.png"
                alt=""
              ></span><span>打开微信，扫描二维码支付</span></div>
        </div>
        <div class="paydialog_right">
          <div class="paydialog_right_money">&yen;{{ user.money / 100 }}</div>
          <div class="paydialog_right_account">账户余额</div>
          <div class="paydialog_right_btns">
            <div
              :class="[item.checked ? 'paydialog_right_btns_item--checked': 'paydialog_right_btns_item']"
              v-for="(item,index) in payBtns"
              :key="index"
              @click="onPayBtnClick(index)"
            >{{ item.value }}元
            </div>
          </div>
          <div
            class="paydialog_right_pay"
            v-show="false"
          >
            <div><span><img
                  src="../../assets/images/wxpaygreen.png"
                  alt=""
                ></span><span>微信支付</span></div>
            <span><img
                src="../../assets/images/blackcheck.png"
                alt=""
              ></span>
          </div>
        </div>
      </div>

    </el-dialog>
    <!--  证书 dialog  -->
    <el-dialog
      v-model="profDialogVisible"
      title="我的证书"
    >
      <div class="profdialog">
        <div class="profdialog_left">
          <div
            class="profdialog_left_bg"
            :style="{backgroundImage: 'url('+profDetail.detail.bgImgUrl+')',backgroundSize: '414px 583px'}"
          >
            <div
              style="text-align: left"
              v-for="(item,index) in profDetail.profArr"
              :key="index"
              :style="[{position:'absolute'},{top: item.pcpos.top},{left: item.pcpos.left},{width: '320px'}]"
            >
              {{item.name==="文字描述" ? "" : item.name}} :
              <span v-if="item.colname==='cardnum'">
                {{item.cardnum}}
              </span>
              <span v-else-if="item.colname==='truename'">
                {{item.truename}}
              </span>
              <span v-else-if="item.colname==='info'">
                {{item.info}}
              </span>
              <span v-else-if="item.colname==='company'">
                {{item.company}}
              </span>
              <span v-else-if="item.colname==='creatTime'">
                {{item.creatTime}}
              </span>
            </div>

            <!-- <div class="profdialog_left_bg_stationname">山东阳光志愿者服务平台</div>
            <div class="profdialog_left_bg_title">荣誉证书</div>
            <div class="profdialog_left_bg_username"> {{ profDetail.detail.order1 }}</div>
            <div class="profdialog_left_bg_info">
              {{ profDetail.detail.order2 }}
            </div>
            <div class="profdialog_left_bg_company">{{ profDetail.detail.order3 }}</div>
            <div class="profdialog_left_bg_date"> {{ profDetail.detail.order4 }}</div> -->
          </div>
          <div class="profdialog_left_num">
            {{ profDetail.detail.order5 }}
          </div>
        </div>
        <div class="profdialog_right">
          <div
            class="profdialog_right_item"
            v-for="(item,index) in profList"
            :key="index"
          >
            <div
              class="profdialog_right_item_left"
              @click="changeProfDetail(item.id)"
            >
              <div class="profdialog_right_item_left_pic">
                <img
                  src="../../assets/images/do.png"
                  alt=""
                >
              </div>
              <div class="profdialog_right_item_left_info">
                <div class="profdialog_right_item_left_info_title">{{ item.profName }}</div>
                <div class="profdialog_right_item_left_info_profnum">{{ item.profNum }}</div>
                <div class="profdialog_right_item_left_info_date"> {{ item.createTime }}</div>
              </div>
            </div>
            <div class="profdialog_right_item_right">
              <div class="profdialog_right_item_right_div">
                <el-divider direction="vertical"></el-divider>
              </div>

              <div
                class="profdialog_right_item_right_print"
                @click="toPrint"
              >打印证书</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container_block_page">
        <pagination
          v-show="totalProf > 0"
          :total="totalProf"
          v-model:page="queryProf.pageNum"
          v-model:limit="queryProf.pageSize"
          @pagination="getProfList"
        />

      </div>
    </el-dialog>

    <!--  申请证书弹窗dialog  -->
    <el-dialog
      v-model="profApplyDialogVisible"
      title=""
    >
      <div class="profdialogApply">
        <div class="profdialogApply_desc">
          <div class="profdialogApply_desc_title">申请证书</div>
          <div class="profdialogApply_desc_detail">
            <el-form-item prop="reason">
              <el-input
                v-model="reason"
                class="w-50 m-2"
                size="large"
                placeholder="请输入证书申请原因"
                type="textarea"
              />
            </el-form-item>
          </div>
        </div>
        <div class="profdialogApply_btn">
          <el-button
            type="warning"
            @click="submitApplyProf"
          >提交申请</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 申请入驻 -->
    <el-dialog
      v-model="applyStationDialogVisible"
      title="申请成为站长"
    >
      <div class="applyDialog">
        <el-form
          :model="formApplyStation"
          label-position="top"
          label-width="100px"
          :inline="true"
        >
          <div class="applyDialog_info">
            <div class="applyDialog_info_title">个人信息</div>
            <div class="applyDialog_info_desc">
              <el-form-item
                label="姓名"
                prop="truename"
              >
                <el-input
                  v-model="formApplyStation.truename"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                  disabled
                />

              </el-form-item>
              <el-form-item
                label="性别"
                prop="sex"
              >
                <el-select
                  v-model="formApplyStation.sex"
                  placeholder="请选择"
                  disabled
                >
                  <el-option
                    label="男"
                    value="0"
                  />
                  <el-option
                    label="女"
                    value="1"
                  />
                </el-select>

              </el-form-item>
              <el-form-item
                label="所在站点"
                prop="stationId"
              >
                <el-select v-model="formApplyStation.stationId">
                  <el-option
                    v-for="object in stationOptions"
                    :key="object.id"
                    :label="object.name"
                    :value="object.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                  label="选择身份"
                  prop="identity"
              >
                <el-select v-model="formApplyStation.identity">
                  <el-option label="站长" value="1"></el-option>
                  <el-option label="副站长" value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="applyDialog_desc">
            <div class="applyDialog_desc_title">申请说明</div>
            <div class="applyDialog_desc_detail">
              <el-form-item prop="describe">
                <el-input
                  v-model="formApplyStation.describe"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                  type="textarea"
                />
              </el-form-item>
            </div>
          </div>
          <div class="applyDialog_btn">
            <el-button
              type="warning"
              @click="submitApplyStation"
            >提交申请
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!-- 申请成为志愿者   -->
    <el-dialog
      v-model="applyDialogVisible"
      title="申请成为志愿者"
    >
      <div class="applyDialog">
        <el-form
          :model="formApply"
          label-position="top"
          label-width="100px"
          :inline="true"
        >
          <div class="applyDialog_info">
            <div class="applyDialog_info_title">个人信息</div>
            <div class="applyDialog_info_desc">
              <el-form-item
                label="姓名"
                prop="truename"
              >
                <el-input
                  v-model="formApply.truename"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                />

              </el-form-item>
              <el-form-item
                label="性别"
                prop="sex"
              >
                <el-select
                  v-model="formApply.sex"
                  placeholder="请选择"
                >
                  <el-option
                    label="男"
                    value="0"
                  />
                  <el-option
                    label="女"
                    value="1"
                  />
                </el-select>

              </el-form-item>
              <el-form-item
                label="联系电话"
                prop="phone"
              >
                <el-input
                  v-model="formApply.phone"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                  clearable
                />

              </el-form-item>
              <el-form-item
                label="常住地址"
                prop="address"
              >
                <el-input
                  v-model="formApply.address"
                  class="w-50 m-2"
                  size="large"
                  clearable
                  placeholder="请输入"
                />
              </el-form-item>
            </div>
          </div>
          <div class="applyDialog_info">
            <div class="applyDialog_info_title">队伍选择</div>
            <div class="applyDialog_info_desc">
              <el-form-item
                label="选择站点"
                prop="stationId"
              >
                <el-select
                  v-model="formApply.stationId"
                  @change="getTeamById(formApply.stationId)"
                >
                  <el-option
                    v-for="object in stationOptions"
                    :key="object.id"
                    :label="object.name"
                    :value="object.id"
                  ></el-option>
                </el-select>

              </el-form-item>
              <el-form-item
                label="选择队伍"
                prop="sex"
              >
                <el-select v-model="formApply.teamId">
                  <el-option
                    v-for="object in teamOptions"
                    :key="object.id"
                    :label="object.name"
                    :value="object.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="describe"
                label="申请说明"
              >
                <el-input
                  v-model="formApply.describe"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入"
                />
              </el-form-item>
            </div>
          </div>
          <div class="applyDialog_btn">
            <el-button
              type="warning"
              @click="submitApply"
            >提交申请
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!-- 申请成为队长   -->
    <el-dialog
        v-model="applyCaptainDialogVisible"
        title="申请成为队长"
    >
      <div class="applyDialog">
        <el-form
            :model="formApplyCaptain"
            label-position="top"
            label-width="100px"
            :inline="true"
        >
          <div class="applyDialog_info">
            <div class="applyDialog_info_title">个人信息</div>
            <div class="applyDialog_info_desc">
              <el-form-item
                  label="姓名"
                  prop="truename"
              >
                <el-input
                    v-model="formApplyCaptain.truename"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                />

              </el-form-item>
              <el-form-item
                  label="性别"
                  prop="sex"
              >
                <el-select
                    v-model="formApplyCaptain.sex"
                    placeholder="请选择"
                >
                  <el-option
                      label="男"
                      value="0"
                  />
                  <el-option
                      label="女"
                      value="1"
                  />
                </el-select>

              </el-form-item>
              <el-form-item
                  label="联系电话"
                  prop="phone"
              >
                <el-input
                    v-model="formApplyCaptain.phone"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                    clearable
                />

              </el-form-item>
            </div>
          </div>
          <div class="applyDialog_info">
            <div class="applyDialog_info_title">队伍选择</div>
            <div class="applyDialog_info_desc">

              <el-form-item
                  label="选择队伍"
                  prop="teamId"
              >
                <el-select v-model="formApplyCaptain.teamId">
                  <el-option
                      v-for="object in teamOptions2"
                      :key="object.id"
                      :label="object.name"
                      :value="object.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                  label="选择身份"
                  prop="identity"
              >
                <el-select v-model="formApplyCaptain.identity">
                  <el-option label="队长" value="2"></el-option>
                  <el-option label="副队长" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                  prop="describe"
                  label="申请说明"
              >
                <el-input
                    v-model="formApplyCaptain.describe"
                    class="w-50 m-2"
                    size="large"
                    placeholder="请输入"
                />
              </el-form-item>
            </div>
          </div>
          <div class="applyDialog_btn">
            <el-button
                type="warning"
                @click="submitApplyCaptain"
            >提交申请
            </el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!-- 收获地址 dialog-->
    <el-dialog
      v-model="addressDialogVisible"
      title="收货地址"
    >
      <div class="addressDialog">
        <el-form
          :model="formRe"
          label-position="top"
          label-width="100px"
          :inline="true"
        >
          <div class="addressDialog_area">
            <el-form-item
              label="姓名"
              prop="stationId"
            >
              <el-input
                v-model="formRe.name"
                class="w-50 m-2"
                size="large"
                placeholder="请输入"
              />

            </el-form-item>
            <el-form-item
              label="电话"
              prop="stationId"
            >
              <el-input
                v-model="formRe.phonenumber"
                class="w-50 m-2"
                size="large"
                placeholder="请输入"
              />

            </el-form-item>
            <el-form-item
              label="详细地址"
              prop="stationId"
            >
              <el-input
                v-model="formRe.address"
                class="w-50 m-2"
                size="large"
                placeholder="请输入"
              />

            </el-form-item>
          </div>

          <div class="addressDialog_btn">
            <el-button
              type="default"
              @click="submitAddress"
            >保存收货地址
            </el-button>
          </div>

        </el-form>
      </div>

    </el-dialog>
    <div
      v-if="profDialogVisible"
      style="display: none;background: #f5f5f5; "
      id="print"
    >
      <div style="height: 913px;width: 650px;background-color: #fff;margin: 30px auto;">
        <div :style="[{backgroundImage: 'url('+profDetail.detail.bgImgUrl+')'},{width: '650px',height: '913px',margin: '0 auto',backgroundPosition:'center center',backgroundSize:'650px 913px'}]">
          <div style="margin-top: 10px;">
            <div style="color: #424242;font-size: 18px;text-align: center;">
              <div style="padding-top: 80px;">山东阳光志愿者服务平台</div>
            </div>
            <div style="color: #ad8427;font-size: 72px;margin-top: 100px;font-weight: bold;text-align: center;">荣誉证书</div>
            <div style="font-size: 28px;color: #212121;margin: 80px;text-align: center"> {{ profDetail.detail.order1 }}</div>
            <div style="font-size: 16px;color: #212121;font-weight: bold;text-indent: 20px;text-align: left;padding: 0 60px;">{{ profDetail.detail.order2 }}</div>
            <div style=" margin-top: 70px;color: #757575;font-size: 14px;text-align: right;font-weight: bold;padding: 0 80px;">{{ profDetail.detail.order3 }}</div>
            <div style=" margin-top: 13px;color: #757575;font-size: 14px;padding: 0 70px;"> {{ profDetail.detail.order4 }}</div>
          </div>

        </div>
        <div class="profdialog_left_num">
          {{ profDetail.detail.order5 }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup name="MemberCenter">
import { reactive, ref, toRefs } from "vue-demi"
import {
  apply,
  applyToCaptain,
  applyToStation,
  getTeamList,
  getTeamListNew,
  getUserInfo,
  updateUserInfo
} from "@/api/user";
import { getLengthUserRange } from "@/api/vipsetting";
import { socketUrl, target } from "@/utils/URL";
import { getToken, getUserId, removeToken } from "@/utils/auth";
import { ElMessage } from "element-plus";
import { getMessageDetail, getMessageList } from "@/api/message";
import { getStationList } from "@/api/home";
import { pay } from "@/api/user"
import { getMyPubList, getSignupList } from "@/api/activity";
import {
  getMyExchange,
  getMyExchangeScore,
  getAddressList,
  deleteAddressById,
  addAddress,
  updateAddress
} from "@/api/shop";
import { getScoreRecord } from "@/api/score";
import { getCertification, getCertifList, submit } from "@/api/certificate";
import QrcodeVue from 'qrcode.vue';
import Pagination from '@/components/Pagination'
import { ElMessageBox } from 'element-plus'
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter()
const tablist = reactive(["全部活动", "待审核", "已驳回", "已完成", "进行中", "报名中", "未开始"])
const payBtns = ref([{ id: 1, checked: true, value: 100 }, { id: 2, checked: false, value: 200 }, {
  id: 3,
  checked: false,
  value: 300
}, { id: 4, checked: false, value: 400 }, { id: 5, checked: false, value: 500 }, { id: 6, checked: false, value: 600 }])
const showMsg = ref(false)
const showDefault = ref(true)
const showPerson = ref(false)
const showAddress = ref(false)
const showAct = ref(true)
const tabIndex = ref(0)
const payDialogVisible = ref(false)
const qrCodeUrl = ref("")
const orderId = ref("")
const profDialogVisible = ref(false)
const profApplyDialogVisible = ref(false)
const applyDialogVisible = ref(false)
const applyCaptainDialogVisible = ref(false)
const applyStationDialogVisible = ref(false)
const addressDialogVisible = ref(false)
const formdata = reactive({
  form: {
    periodList: {}
  }
})
const payMoney = ref(100)
const { form } = toRefs(formdata)
const formRedata = reactive({ formRe: { id: null, name: '', address: '', phonenumber: '' } })
const { formRe } = toRefs(formRedata)
const formAddressdata = reactive({ formAddress: {} })
const { formAddress } = toRefs(formAddressdata)
const formApplyStationdata = reactive({ formApplyStation: {} })
const { formApplyStation } = toRefs(formApplyStationdata)
const formApplydata = reactive({ formApply: {} })
const { formApply } = toRefs(formApplydata)
const formApplyCaptainData = reactive({ formApplyCaptain: {} })
const { formApplyCaptain } = toRefs(formApplyCaptainData)
const activeName = ref("全部活动")
const avatar = ref('../../assets/images/center/header.png');
const exchangeList = ref([]);
const queryChange = ref({ pageSize: 10, pageNum: 1 })
const exchangeTotal = ref(0)
const addressList = ref([]);
const isEdit = ref(false)
const store = useStore();

//打印证书
const toPrint = () => {

  var iframe = document.getElementById("print-iframe");
  if (!iframe) {
    var el = document.getElementById("print");
    iframe = document.createElement('IFRAME');
    var doc = null;
    iframe.setAttribute("id", "print-iframe");
    iframe.setAttribute('style', 'position:absolute;width:0;height:0;left:-500px;top:-500px;');
    document.body.appendChild(iframe);
    doc = iframe.contentWindow.document;
    //这里可以自定义样式
    doc.write('<style media="print">@page {size: portrait;margin: 0mm;}</style>'); //解决出现页眉页脚和路径的问题
    doc.write('<div style="margin: 0 auto;">' + el.innerHTML + '</div>');
    doc.close();
    iframe.contentWindow.focus();
  }
  setTimeout(function () {
    iframe.contentWindow.print();
  }, 50)  //解决第一次样式不生效的问题
  if (navigator.userAgent.indexOf("MSIE") > 0) {
    document.body.removeChild(iframe);
  }
}
//收货地址列表
const getAddressLists = async () => {
  let result = await getAddressList();
  if (result.code === 200) {
    addressList.value = result.data;

  }
}
//修改地址
const editAddress = (item) => {
  isEdit.value = true
  addressDialogVisible.value = true
  formRe.value.id = item.id
  formRe.value.name = item.name
  formRe.value.address = item.address
  formRe.value.phonenumber = item.phonenumber
}

const submitAddress = async () => {
  if (isEdit.value === false) {
    let result = await addAddress(formRe.value)
    if (result.code === 200) {
      ElMessage({
        message: '添加成功',
        type: 'success',
      })
      getAddressLists()
    } else {
      ElMessage.error('添加失败')
    }
  } else {
    let result = await updateAddress(formRe.value)
    if (result.code === 200) {
      ElMessage({
        message: '更新成功',
        type: 'success',
      })
      getAddressLists()
    } else {
      ElMessage.error('更新失败')
    }
  }
  addressDialogVisible.value = false
}

//删除地址
const delAddress = (id) => {
  ElMessageBox.confirm('确定要删除此地址吗?')
    .then(async () => {
      let result = await deleteAddressById(id);
      if (result.code === 200) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        getAddressLists()
      }
    })
    .catch(() => {
      // catch error
    })
}


//兑换列表
const score = ref({});
const getScore = async () => {
  let response = await getMyExchangeScore();
  score.value = response.msg
}
getScore();

const getExchangeList = async () => {
  let response = await getMyExchange(queryChange.value);
  if (response.code === 200) {
    exchangeList.value = response.rows
    exchangeTotal.value = response.total
  }
}


const typeFormatter = (row) => {
  if (row.changeType === '1') {
    return "减少"
  } else {
    return "增加"
  }
}

//积分记录
const queryScore = ref({})
queryScore.value = { pageNum: 1, pageSize: 10 }
const totalScore = ref(0)
const scoreList = ref([])
const getScoreList = async () => {
  let response = await getScoreRecord(queryScore.value);
  if (response.code === 200) {
    scoreList.value = response.rows
    totalScore.value = response.total
  }
}

//我的参与
const queryJoin = ref({})
queryJoin.value = { pageNum: 1, pageSize: 3 }
const totalJoin = ref(0)
const actJoinList = ref([])
const activityJoinList = async () => {
  let response = await getSignupList(queryJoin.value, { status: "1" });
  if (response.rows.length > 0) {
    actJoinList.value = response.rows
    totalJoin.value = response.total
  } else {
    actJoinList.value = []
  }
}

const toActDetail = (id) => {
  router.push({
    path: 'activityDetail',
    query: {
      id: id
    }
  })
}


const queryAct = ref({})
//我的发布列表
const actList = ref([]);
queryAct.value = { pageNum: 1, pageSize: 3, statusQ: "0" }
const totalAct = ref(0)
const activityList = async () => {
  let response = await getMyPubList(queryAct.value);
  if (response.rows.length > 0) {
    if (actList.value.length > 0) {
      actList.value = []
    }
    actList.value = response.rows
    totalAct.value = response.total
  } else {
    actList.value = []
    totalAct.value = 0
  }
}
activityList();
const handleActClick = (tab) => {
  console.log(tab.index)
  switch (tab.index) {
    case "0":
      console.log("22222")
      queryAct.value.statusQ = "0"
      activityList()
      break;
    case "1":
      queryAct.value.statusQ = "1"
      activityList()
      break;
    case "2":
      queryAct.value.statusQ = "2"
      activityList()
      break;
    case "3":
      queryAct.value.statusQ = "3"
      activityList()
      break;
    case "4":
      queryAct.value.statusQ = "4"
      activityList()
      break;
    case "5":
      queryAct.value.statusQ = "5"
      activityList()
      break;
    case "6":
      queryAct.value.statusQ = "6"
      activityList()
      break;
  }
}

var timer = null
var socket;
const onPayBtnClick = async (index) => {
  payBtns.value[index].checked = true;
  payMoney.value = payBtns.value[index].value
  for (let i in payBtns.value) {
    if (i != index) {
      payBtns.value[i].checked = false
    }
  }
  const userId = getUserId()
  socket.close()
  orderId.value = ''
  clearInterval(timer)
  socket = new WebSocket(socketUrl)
  let startTime = 10;

  let result = await pay({ money: payMoney.value, payway: 'NATIVE', payType: payType.value })
  if (result?.code === 200) {
    payDialogVisible.value = true
    qrCodeUrl.value = result.data.codeUrl
    orderId.value = result.data.orderId
  }
  function keepalive () {
    if (startTime === 10) {
      startTime = new Date().getTime()
    }
    // 超过1分钟则停止轮询
    if ((new Date().getTime() - startTime) > 60 * 1000) {
      ElMessage({
        message: '支付时间超过一分钟，请刷新！',
        type: "error"
      })
      orderId.value = ''
      clearInterval(timer)
      socket.close()
      return
    }
    var chatMsg = {}
    chatMsg.type = 4
    //支付类型
    chatMsg.status = "3"
    chatMsg.orderId = orderId.value
    chatMsg.senderId = userId
    socket.send(JSON.stringify(chatMsg))
    console.log("发送心跳消息")
  }
  function onConnect () {
    timer = setInterval(function () {
      keepalive()
    }, 10000)
  }
  if (orderId.value != null && orderId.value !== "") {
    //打开事件
    socket.onopen = function () {
      onConnect()
    }
    socket.onclose = function () {
      clearInterval(timer)
    }
    socket.onerror = function () {
      clearInterval(timer)
      socket.close()
      orderId.value = ''
      console.log('连接出错')
    }
    socket.onmessage = function (e) {
      //alert(e.data)
      console.log('eeee========', e)
      const datas = JSON.parse(e.data)
      if (datas.tradeState == '1') {
        ElMessage({
          message: '支付成功',
          type: "success"
        })
        payType.value = ''
        orderId.value = ''
        clearInterval(timer)
        socket.close()
        payDialogVisible.value = false
      }
    }
  }

}
const closePayDialog = () => {
  ElMessageBox.confirm('确定退出支付吗?')
    .then(() => {
      //还要关闭socket和轮询
      payType.value = ''
      orderId.value = ''
      clearInterval(timer)
      socket.close()
      payDialogVisible.value = false
    })
    .catch(() => {
      // catch error
    })
}
const stationOptions = ref([])
const teamOptions = ref([])
const teamOptions2 = ref([])
const getStationListData = async () => {
  let result = await getStationList({ pageNum: 1, pageSize: 1000 });
  stationOptions.value = result.rows
}
getStationListData()
//根据站点id查询队伍
const getTeamById = async (stationId) => {
  formApply.value.teamId = ''
  let query = ref({})
  query.value = { pageNum: 1, pageSize: 1000, stationId: stationId }
  let result = await getTeamList(query.value);
  if (result?.code === 200) {
    teamOptions.value = result.rows
  }
}
const getTeamListAll= async () => {
  let result = await getTeamListNew();
  if (result?.code === 200) {
    teamOptions2.value = result.data
  }
}
const submitApply = async () => {
  if (formApply.value.truename == null) {
    ElMessage({
      message: '请输入真实姓名',
      type: "error"
    })
    return false
  }
  if (formApply.value.sex == null) {
    ElMessage({
      message: '请选择性别',
      type: "error"
    })
    return false
  }
  if (formApply.value.phone == null) {
    ElMessage({
      message: '请输入电话',
      type: "error"
    })
    return false
  }
  if (formApply.value.address == null) {
    ElMessage({
      message: '请输入常住地址',
      type: "error"
    })
    return false
  }
  if (formApply.value.stationId == null) {
    ElMessage({
      message: '请选择站点',
      type: "error"
    })
    return false
  }
  if (formApply.value.teamId == null) {
    ElMessage({
      message: '请选择队伍',
      type: "error"
    })
    return false
  }
  let result = await apply(formApply.value);
  if (result) {
    applyDialogVisible.value = false
  }
}

const submitApplyCaptain = async () => {
  if (formApplyCaptain.value.truename == null) {
    ElMessage({
      message: '请输入真实姓名',
      type: "error"
    })
    return false
  }
  if (formApplyCaptain.value.sex == null) {
    ElMessage({
      message: '请选择性别',
      type: "error"
    })
    return false
  }
  if (formApplyCaptain.value.phone == null) {
    ElMessage({
      message: '请输入电话',
      type: "error"
    })
    return false
  }

  if (formApplyCaptain.value.teamId == null) {
    ElMessage({
      message: '请选择队伍',
      type: "error"
    })
    return false
  }
  if (formApplyCaptain.value.identity == null) {
    ElMessage({
      message: '请选择身份',
      type: "error"
    })
    return false
  }
  let result = await applyToCaptain(formApplyCaptain.value);
  if (result) {
    applyCaptainDialogVisible.value = false
  }
}


const submitApplyStation = async () => {
  if (formApplyStation.value.stationId == null) {
    ElMessage({
      message: '请选择站点',
      type: "error"
    })
    return false
  }
  if (formApplyStation.value.identity == null) {
    ElMessage({
      message: '请选择身份',
      type: "error"
    })
    return false
  }
  let result = await applyToStation(formApplyStation.value);
  if (result) {
    applyStationDialogVisible.value = false
  }
}


const trueHeadImg = ref('')

const uploadFileUrl = ref(target + "common/upload")  // 上传的图片服务器地址
const headers = ref({
  Authorizationapp: getToken(),
})
const beforeUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    ElMessage.error('上传头像图片大小不能超过 2MB!');
  }
  return isLt2M;
}
// 图片文件个数超出
const handleExceed = () => {
  ElMessage.error(`只允许上传单个文件`);
}
const handleSuccess = (res) => {
  console.log(res)
  ElMessage.success("上传成功");
  trueHeadImg.value = res.url;
}
// 图片上传失败
const handleUploadError = () => {
  ElMessage.error("上传失败, 请重试");
}
const reason = ref()
//获取用户信息
const user = ref({})
const typeName = ref("")
const teamName = ref("")
const stationName = ref("")
const time = ref(0)
const havaNext = ref(true)
const per = ref(0)
const userType = ref('')
const starLength = ref([]);
const getUser = async () => {
  console.error("执行了");
  starLength.value = []
  let response = await getUserInfo();
  user.value = response.data
  typeName.value = response.data.typeName
  teamName.value = response.data.teamName
  stationName.value = response.data.stationName
  userType.value = response.data.type

  for (let i = 1; i <= response.data.vipRange; i++) {
    starLength.value.push(1)
  }
  form.value.birthday = response.data.birthday
  form.value.birthplace = response.data.birthplace
  form.value.nation = response.data.nation
  form.value.belief = response.data.belief
  form.value.hukou = response.data.hukou
  form.value.trp = response.data.trp
  form.value.postcode = response.data.postcode
  form.value.correspond = response.data.correspond
  form.value.email = response.data.email
  form.value.wechat = response.data.wechat
  form.value.unitPhone = response.data.unitPhone
  form.value.otherPhone = response.data.otherPhone
  form.value.resume = response.data.resume
  form.value.phone = response.data.phone
  formApplyStation.value.phone = response.data.phone
  formApplyCaptain.value.phone = response.data.phone

  if (response.data.projectList != null) {
    form.value.projectList = response.data.projectList
  }
  if (response.data.periodList != null) {
    form.value.periodList = response.data.periodList
  }
  form.value.username = response.data.username
  form.value.truename = response.data.truename
  formApplyStation.value.truename = response.data.truename
  formApplyCaptain.value.truename = response.data.truename
  form.value.sex = response.data.sex
  formApplyStation.value.sex = response.data.sex
  formApplyCaptain.value.sex = response.data.sex
  form.value.idCard = response.data.idCard
  form.value.political = response.data.political
  form.value.school = response.data.school
  form.value.professional = response.data.professional
  form.value.education = response.data.education
  form.value.employer = response.data.employer
  form.value.skill = response.data.skill
  form.value.hobby = response.data.hobby
  form.value.job = response.data.job
  trueHeadImg.value = response.data.trueHeadImg
  formApplyStation.value.teamId = response.data.vlTeam?.id
  formApplyStation.value.stationId = response.data.vlStation?.id
  let res = await getLengthUserRange({ vipRange: user.value.vipRange + 1 });
  havaNext.value = res.data.haveNext;
  if (havaNext.value === false) {
    per.value = 100
  } else {
    per.value = 100 * (1 - (res.data.timeLen - user.value.timeLen) / res.data.timeLen)
  }
  time.value = res.data.timeLen - user.value.timeLen

}
getUser();


const messageCurrentChange = (val) => {
  messageQuery.value.pageNum = val
  messageOnLoad()
}
const messageUpPage = () => {
  messageQuery.value.pageNum--;
  messageOnLoad()
}
const messageDownPage = () => {
  messageQuery.value.pageNum++;
  messageOnLoad()
}
const messageTotals = ref(6);
const messageList = ref({})
const messageQuery = ref({})

messageQuery.value = { pageNum: 1, pageSize: 8 }
const messageIsEmpty = ref(false)
const messageOnLoad = async () => {
  let response = await getMessageList(messageQuery.value);
  if (response.code === 200) {
    messageList.value = response.rows
    messageTotals.value = response.total
    messageIsEmpty.value = false
  } else if (response?.code === 410) {
    if (messageQuery.value.pageNum == 1) {
      messageIsEmpty.value = true
    }
  } else {
    if (messageQuery.value.pageNum == 1) {
      messageIsEmpty.value = true
    }
  }
}
const showMsgDetail = ref(false);
const messageTitle = ref('');
const messageContent = ref('');
const toMessageDetail = async (val) => {
  let response = await getMessageDetail(val);
  messageTitle.value = response.data.title;
  messageContent.value = response.data.content;
  showMsgDetail.value = true
  showMsg.value = false
  showDefault.value = false
  showPerson.value = false
  showAddress.value = false
  showAct.value = false
}
const toMessageList = () => {
  showMsgDetail.value = false
  showMsg.value = true
  showDefault.value = false
  showPerson.value = false
  showAddress.value = false
  showAct.value = false
}

const format = (percentage) => (percentage === 100 ? 'Full' : '还有' + `${time}` + '小时升级四星')


const handleClick = (tab) => {
  console.log(tab.index)
  tabIndex.value = tab.index
  switch (tab.index) {
    case "0":
      handleActClick({ index: "0" })
      queryAct.value.statusQ = "0"
      activityList()
      break;
    case "1":
      activityJoinList();
      break;
    case "2":
      getScoreList();
      break;
    case "3":
      getExchangeList();
      break;
  }
}
const clickShowMsg = () => {
  showMsg.value = true
  showDefault.value = false
  showPerson.value = false
  showAddress.value = false
  showAct.value = false
  messageOnLoad()
}
const clickShowPerson = () => {
  showMsg.value = false
  showDefault.value = false
  showPerson.value = true
  showAddress.value = false
  showAct.value = false
}


//保存用户信息
const submitUserInfo = async () => {
  form.value.trueHeadImg = trueHeadImg.value;
  let result = await updateUserInfo(form.value);
  if (result.code === 200) {
    ElMessage.success("保存成功");
    await getUser();
  }
}
const clickShowAddress = () => {
  showMsg.value = false
  showDefault.value = false
  showPerson.value = false
  showAddress.value = true
  showAct.value = false
  isEdit.value = false
  getAddressLists()
}
const clickShowAct = () => {
  showAct.value = true
  showMsg.value = false
  showDefault.value = false
  showPerson.value = false
  showAddress.value = false
}
const payType = ref("")
const showPayDialog = async (val) => {
  payType.value = val
  if (val === 'ali') {
    ElMessage.error("暂不支持使用支付宝,请选择其他方式");
    return;
  }
  let result = await pay({ money: payMoney.value, payway: 'NATIVE', payType: val })
  if (result?.code === 200) {
    payDialogVisible.value = true
    qrCodeUrl.value = result.data.codeUrl
    orderId.value = result.data.orderId
  }
  const userId = getUserId()
  console.log("aaa")
  let startTime = 10;
  function keepalive () {
    if (startTime === 10) {
      startTime = new Date().getTime()
    }
    // 超过1分钟则停止轮询
    if ((new Date().getTime() - startTime) > 60 * 1000) {
      ElMessage({
        message: '支付时间超过一分钟，请刷新！',
        type: "error"
      })
      orderId.value = ''
      clearInterval(timer)
      socket.close()
      return
    }
    var chatMsg = {}
    chatMsg.type = 4
    //支付类型
    chatMsg.status = "3"
    chatMsg.orderId = orderId.value
    chatMsg.senderId = userId
    socket.send(JSON.stringify(chatMsg))
    console.log("发送心跳消息")
  }
  function onConnect () {
    timer = setInterval(function () {
      keepalive()
    }, 10000)
  }
  if (orderId.value != null && orderId.value !== '') {
    socket = new WebSocket(socketUrl);
    //打开事件
    socket.onopen = function () {
      onConnect()
    }
    socket.onmessage = function (e) {
      //alert(e.data)
      console.log('eeee========', e)
      const datas = JSON.parse(e.data)
      if (datas.tradeState == '1') {
        ElMessage({
          message: '支付成功',
          type: "success"
        })
        payType.value = ''
        orderId.value = ''
        clearInterval(timer)
        socket.close()
        payDialogVisible.value = false
      }
    }

  }
}
//证书
const profDetail = ref({})
const changeProfDetail = async (val) => {
  let result = await getCertification(val);
  profDetail.value = result.data
  console.error(profDetail.value.detail.bgImgUrl)
}
const showMyprofDialog = () => {

  if (profList.value.length === 0) {
    ElMessage({
      message: '当前用户无证书',
      type: "info"
    })
    return false
  }

  profDialogVisible.value = true
  profDetail.value = profList.value[0]
  changeProfDetail(profList.value[0].id)
}

const showApplyDialog = () => {
  if (user.value.type == '1') {
    //游客身份，成为队员
    formApply.value = {}
    applyDialogVisible.value = true
  }
  if (user.value.type == '2') {
    //队员成为队长
    formApplyCaptain.value = {}
    applyCaptainDialogVisible.value = true
    getTeamListAll()
  }
  if (user.value.type == '3'||user.value.type == '5') {
    //队长（副队长）身份，成为站长
    formApplyStation.value.stationId = null
    formApplyStation.value.describe = null
    applyStationDialogVisible.value = true
  }

}
const showAddressDialog = () => {
  addressDialogVisible.value = true;
  formRe.value.id = null;
}

//证书相关
const queryProf = ref({})
queryProf.value = { pageNum: 1, pageSize: 3 }
const totalProf = ref(0)
const profList = ref([])
const getProfList = async () => {
  let response = await getCertifList(queryProf.value);
  if (response.code == 200) {
    if (response.rows.length > 0) {
      profList.value = response.rows
      totalProf.value = response.total
    } else {
      profList.value = []
    }
  }

}
getProfList();

//退出登录
const logout = async () => {
  window.localStorage.clear()
  window.sessionStorage.clear()
  store.commit("setStationIds", null);
  removeToken()
  router.push({ path: "/login" })
}
//证书提交
const submitApplyProf = async () => {
  const parameter = ref({})
  parameter.value = {
    reason: reason.value
  };
  let result = await submit(parameter.value);
  if (result?.code === 200) {
    ElMessage.success("提交成功")
    profApplyDialogVisible.value = false;
  } else {
    ElMessage.error(result?.msg);
  }


}

const showProfApply = () => {
  profApplyDialogVisible.value = true;
  reason.value = '';
}

</script>

<style lang="scss" scoped>
::v-deep .el-input__wrapper {
  height: 40px !important;
}
::v-deep .el-checkbox-group {
  background-color: #f4f4f4;
  padding: 5px;
}
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #ff6200;
  font-size: 32px;
  font-weight: 600;
}

:deep(.el-tabs__item) {
  font-size: 20px;
  color: #505559;
}

:deep(.el-tabs__item.is-active) {
  font-size: 20px;
  color: #ff6200;
}

:deep(.el-tabs__active-bar) {
  background-color: #ff6200;
}

:deep(.el-dialog) {
  background: #f5f5f5;
}

.container {
  height: 100vh;
  background-color: #f5f5f5;
  padding: 30px 30px 30px 30px;
  overflow-y: scroll;

  &_top {
    position: relative;
    background-color: #fff;
    padding: 30px 30px 30px 30px;
    border-radius: 6px;

    &_info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &_left {
        display: flex;
        flex-direction: row;

        &_header {
          margin-right: 20px;

          img {
            height: 120px;
            width: 120px;
            border-radius: 50%;
          }
        }

        &_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          line-height: 0;
          text-align: center;

          &_title {
            font-size: 28px;
            color: #212121;
            font-weight: bold;
          }

          &_level {
            display: flex;
            align-items: center;

            &_star {
              font-size: 14px;
              color: #9e9e9e;
              vertical-align: middle;
            }

            &_images {
              display: flex;
              align-items: center;

              img {
                margin-left: 5px;
                width: 14px;
                height: 14px;
              }
            }
          }

          &_timelen {
            font-size: 14px;
            color: #505559;
            font-weight: bold;
          }
        }
      }

      &_prof {
        display: flex;
        flex-direction: row;
        &_right {
          border: 1px solid rgba(255, 153, 89, 100);
          padding: 30px 50px 30px 50px;
          border-radius: 6px;
          font-size: 20px;
          color: #ff9959;
          text-align: center;
        }

        &_right img {
          height: 20px;
          vertical-align: middle;
          margin-right: 10px;
        }
        &_me {
          border: 1px solid rgba(255, 153, 89, 100);
          padding: 30px 50px 30px 50px;
          border-radius: 6px;
          margin-left: 20px;
          font-size: 20px;
          color: white;
          text-align: center;
          background-color: rgba(255, 153, 89, 100);
        }

        &_me img {
          height: 20px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      &_right {
        border: 1px solid rgba(255, 153, 89, 100);
        padding: 30px 50px 30px 50px;
        border-radius: 6px;
        font-size: 20px;
        color: #ff9959;
        text-align: center;
      }

      &_right img {
        height: 20px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    &_progress {
      width: 100%;
      position: absolute;
      margin-top: 30px;
      bottom: 0;
      left: 0;
    }
  }

  &_content {
    margin-top: 30px;
    display: flex;
    flex-direction: row;

    &_left {
      margin-right: 30px;
      flex: 2;

      &_block {
        margin-bottom: 30px;
        background-color: #fff;
        //padding: 20px 20px;
        border-radius: 6px;
        font-size: 14px;
        text-align: center;
        padding: 20px 20px 20px 20px;

        &_div {
          //padding: 0 10px 0 10px;
        }

        &_row {
          border-radius: 6px;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          line-height: 0px;
          text-align: center;

          &_left {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #505559;
            font-size: 16px;

            &_title {
              span {
                display: inline-block;
                line-height: 0px;
                text-align: center;
                vertical-align: middle;
              }
            }

            img {
              height: 30px;
              vertical-align: middle;
            }

            &_money {
              margin-top: 22px;
              color: #000000;
              font-size: 18px;
            }
          }

          &_right {
            img {
              height: 20px;
            }
          }
        }
      }

      &_pay {
        margin-bottom: 30px;
        background-color: #fff;
        padding: 20px 20px;
        border-radius: 6px;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        line-height: 0px;
        text-align: center;

        &_left {
          display: flex;
          flex-direction: column;
          text-align: center;
          color: #505559;
          font-size: 16px;

          &_title {
            span {
              display: inline-block;
              line-height: 0px;
              text-align: center;
              vertical-align: middle;
            }
          }

          img {
            height: 30px;
            vertical-align: middle;
          }

          &_money {
            margin-top: 22px;
            color: #000000;
            font-size: 18px;
          }
        }

        &_right {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    &_right {
      flex: 8;

      &_counts {
        color: #c2c7cc;
        font-size: 16px;
        text-align: left;
        margin-top: 50px;
        //margin-left: 20px;
        margin-bottom: 20px;
      }

      &_title {
        font-size: 28px;
        color: #ff9959;
        text-align: left;
      }

      &_table {
        margin-top: 30px;
      }

      &_list {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        &_item {
          width: 43%;
          background: #fafafa;
          border-radius: 6px;
          display: flex;
          flex-direction: row;
          padding: 20px 20px;
          margin-right: 30px;
          margin-bottom: 30px;

          &_pic {
            margin-right: 20px;
          }

          &_pic img {
            height: 120px;
          }

          &_info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: left;

            &_title {
              color: #212121;
              font-size: 18px;
            }

            &_price {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              color: #bdbdbd;
              font-size: 14px;
            }
          }
        }
      }

      &_acts {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        &_item {
          width: 440px;
          border-radius: 6px;
          background-color: rgba(255, 255, 255, 100);
          text-align: center;
          box-shadow: 0px 0px 5px 0px rgba(224, 224, 224, 100);
          margin-right: 20px;
          margin-bottom: 20px;

          &_pic {
            img {
              height: 220px;
              width: 100%;
            }
          }

          &_info {
            padding: 20px 20px;

            &_title {
              font-size: 20px;
              color: #212121;
            }
          }

          &_tags {
            padding: 30px 20px 20px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &_leftpart {
              display: flex;
              flex-direction: row;
            }

            &_rightpart {
              font-size: 16px;
              color: #505559;
            }
          }
        }
      }

      &_default {
        border-radius: 6px;
        padding: 20px 20px;
        background-color: #fff;
      }

      &_msg {
        border-radius: 6px;
        padding: 20px 20px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_page {
          text-align: center;
        }

        &_empty {
          padding: 0 30px 30px 30px;
        }

        &_title {
          margin-left: 60px;
          width: 100%;
          font-size: 20px;
          color: #505559;
          text-align: left;
        }

        &_content {
          line-height: 30px;
          text-align: justify;
        }

        &_list {
          width: 100%;

          img {
            height: 24px;
            vertical-align: middle;
          }

          &_item {
            line-height: 24px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &_bell {
              margin-right: 10px;
            }

            &_date {
              margin-right: 10px;
            }
          }
        }
      }

      &_material {
        width: 100%;

        &_block--width {
          width: 100%;
        }

        &_block {
          border-radius: 6px;
          padding: 20px 20px;
          background-color: #fff;
          margin-bottom: 20px;

          &_title {
            color: #505559;
            font-size: 20px;
            text-align: left;
          }

          &_titles {
            color: #ff6200;
            font-size: 20px;
            text-align: left;
          }

          &_titleaddress {
            width: 100%;
            color: #505559;
            font-size: 20px;
            text-align: left;
            vertical-align: middle;
            display: flex;
            justify-content: space-between;

            &_edit {
              color: #ff9959;
              font-size: 16px;
              text-align: center;
            }

            &_del {
              color: #ff2525;
              font-size: 16px;
              text-align: center;
            }
          }

          &_titleaddress img {
            height: 20px;
            vertical-align: middle;
          }

          &_content {
            display: flex;
            flex-direction: row;
          }
        }

        &_btn {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  &_block {
    &_page {
      width: 100%;
    }
  }
}

:deep(.el-button--warning) {
  width: 350px;
  height: 60px;
  color: #3b2f00;
  background-color: #ffcf1a;
  border: 1px solid #ffcf1a;
  font-size: 20px;
}

.paydialog {
  display: flex;
  flex-direction: row;

  &_left {
    flex: 1;
    background: #fff;
    padding: 50px 50px 50px 50px;

    &_recharge {
      color: #212121;
      font-size: 20px;

      &_money {
        margin-left: 5px;
        color: #ff9959;
      }
    }

    &_qrcode {
      margin-top: 30px;
    }

    &_qrcode img {
      height: 400px;
    }

    &_btn {
      background: #52cc6f;
      padding: 25px 15px 25px 15px;
      border-radius: 6px;
      color: white;
      font-size: 20px;
    }

    &_btn img {
      height: 30px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  &_right {
    background: #fff;
    padding: 50px 50px 50px 50px;
    flex: 1;

    &_money {
      font-size: 28px;
      color: #ff9100;
    }

    &_account {
      font-size: 14px;
      color: #212121;
    }

    &_btns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 140px;

      &_item {
        height: 40px;
        width: 80px;
        background: #f5f5f5;
        color: #101010;
        margin-right: 20px;
        margin-bottom: 20px;
        text-align: center;
        line-height: 40px;
        border-radius: 6px;
      }

      &_item--checked {
        height: 40px;
        width: 80px;
        background: #ff9100;
        color: #101010;
        margin-right: 20px;
        margin-bottom: 20px;
        text-align: center;
        line-height: 40px;
        border-radius: 6px;
      }
    }

    &_pay {
      border: 1px solid rgba(189, 189, 189, 100);
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 100);
      padding: 20px 20px;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 50px;

      img {
        height: 30px;
        vertical-align: middle;
      }
    }
  }
}

.profdialog {
  display: flex;
  flex-direction: row;
  background: #f5f5f5;

  &_left {
    flex: 1;
    padding: 20px 20px;
    background-color: #fff;

    &_num {
      margin-top: 20px;
    }

    &_bg {
      background-image: url("../../assets/images/profbg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 40px 30px 40px 30px;
      height: 520px;
      position: relative &_stationname {
        color: #424242;
        font-size: 18px;
      }

      &_title {
        color: #ad8427;
        font-size: 72px;
        margin-top: 50px;
        font-weight: bold;
      }

      &_username {
        font-size: 28px;
        color: #212121;
        margin: 50px;
      }

      &_info {
        font-size: 16px;
        color: #212121;
        font-weight: bold;
        text-indent: 20px;
        text-align: left;
      }

      &_profs {
        margin-top: 80px;
        text-align: left;
        font-size: 14px;
        color: #212121;
        font-weight: bold;
      }

      &_company {
        margin-top: 70px;
        color: #757575;
        font-size: 14px;
        text-align: right;
        font-weight: bold;
      }

      &_date {
        margin-top: 13px;
        color: #757575;
        font-size: 14px;
        text-align: right;
      }
    }
  }

  &_left img {
    height: 600px;
  }

  &_right {
    margin-left: 20px;
    flex: 1;

    &_item {
      background: #fff;
      padding: 20px 20px;
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      border-radius: 6px;

      &_left {
        display: flex;
        flex-direction: row;

        &_pic {
        }

        &_pic img {
          height: 80px;
          margin-right: 10px;
        }

        &_info {
          position: relative;
          text-align: left;

          &_title {
            font-size: 18px;
            color: #212121;
            font-weight: bold;
          }

          &_profnum {
            margin-top: 5px;
            font-size: 14px;
            color: #505559;
          }

          &_date {
            position: absolute;
            bottom: 0;
            font-size: 14px;
            color: #c2c7cc;
          }
        }
      }

      &_right {
        display: flex;
        flex-direction: row;
        align-items: center;

        &_div {
        }

        &_print {
          font-size: 18px;
          color: #ff9959;
        }
      }
    }
  }
}
.profdialogApply {
  &_desc {
    width: 90%;
    padding: 30px 45px 30px 45px;
    background: #fff;
    border-radius: 6px;

    &_title {
      text-align: left;
      font-weight: bold;
    }

    &_detail {
      margin-top: 30px;
    }
  }

  &_btn {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.applyDialog {
  &_info {
    width: 100%;
    padding: 30px 45px 30px 45px;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 10px;

    &_title {
      text-align: left;
      font-size: 20px;
      font-weight: bolder;
    }

    &_desc {
      margin-top: 50px;
      display: flex;
      flex-direction: row;
    }
  }

  &_desc {
    margin-top: 30px;
    width: 100%;
    padding: 30px 45px 30px 45px;
    background: #fff;
    border-radius: 6px;

    &_title {
      text-align: left;
    }

    &_detail {
      margin-top: 30px;
    }
  }

  &_btn {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.addressDialog {
  &_area {
    display: flex;
    flex-direction: row;
  }

  &_btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

:deep(.el-button--default) {
  width: 155px;
  height: 60px;
  color: #3b2f00;
  background-color: #fff;
  border: 1px solid #ff9959;
  font-size: 20px;
  color: #ff9959;
}

.avatar-uploader .avatar {
  width: 200px;
  height: 250px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 250px;
  text-align: center;
  border: 1px solid #8c939d;
}

:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #ff9959;
}
</style>
<style lang="scss">
//打印机媒体查询
@media print {
  #print-iframe {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .profdialog {
    display: flex;
    flex-direction: row;
    background: #f5f5f5;
  }
  .profdialog_left {
    flex: 1;
    padding: 20px 20px;
    background-color: #fff;
  }
  .profdialog_left_num {
    margin-top: 20px;
  }
  .profdialog_left_bg {
    background-image: url("../../assets/images/profbg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 40px 30px 40px 30px;
  }
  .profdialog_left_bg_stationname {
    color: #424242;
    font-size: 18px;
  }

  .profdialog_left_bg_title {
    color: #ad8427;
    font-size: 72px;
    margin-top: 50px;
    font-weight: bold;
  }

  .profdialog_left_bg_profs {
    margin-top: 80px;
    text-align: left;
    font-size: 14px;
    color: #212121;
    font-weight: bold;
  }
}
</style>
